<template>
    <ODropdown ref="dropdown" @beforeclose="onBeforeClose" @open="onOpen" @beforeopen="onBeforeOpen">
        <template #default="{ target }">
            <slot name="target" :target="target" :open="handleFocus">
                <div class="position-relative" :class="wrapperClass">
                    <input :id="id" :ref="target" v-model="internalValue" v-bind="$attrs" @focus="handleFocus" @keydown.enter="handleRawEnterPress" @input="autocompleteControl.searchWithDebounce" @blur="onBlur"
                        autocomplete="off">
                    <span class="loading-indicator" v-if="autocompleteControl.isLoading">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </span>
                </div>
            </slot>
        </template>
        <template #dropdown="scope">
            <div :ref="scope.container" v-show="hideNoResults ? autocompleteControl.isLoaded && autocompleteControl.data.length > 0 : autocompleteControl.isLoaded" 
                class="shadow dropdown-menu card o365-autocomplete-container" 
                :style="{'min-width': dropdownWidth+'px'}">
                <div v-if="autocompleteControl.data.length === 0" class="px-2">
                    <slot name="noResults">
                        <span class="text-muted">{{$t('No results found')}}</span>
                    </slot>
                </div>
                
                <div ref="containerRef" class="autocomplete-scroll-container position-relative overflow-auto">
                    <div :style="{'min-height': autocompleteControl.data.length * itemSize + 'px'}">
                        <div v-for="(row, index) in autocompleteControl.data" class="hstack hover-wrapper o365-autocomplete-item"
                            :class="{'focussed': index === autocompleteControl.navigation.currentIndex}">
                            <button class="dropdown-item py-0" 
                                :style="{'height': itemSize}"
                                :title="itemTitle ? itemTitle(row) : row[field]" @click="() => { autocompleteControl.select(row)}">
                                <slot :row="row">
                                    {{row[field]}}
                                </slot>
                            </button>
                            <template v-if="row._recent">
                                <div class="hide-on-hover" style="width: 32px;"></div>
                                <button class="btn btn-sm btn-link show-on-hover ms-auto py-0"  @click="() => autocompleteControl.removeRecent(index)"
                                    :title="$t('Remove recent selection')">
                                    <i class="bi bi-x-lg"></i>
                                </button>
                            </template>
                        </div>
                        <button v-if="autocompleteControl.canShowMore" class="dropdown-item o365-autocomplete-item text-primary py-0" 
                            :style="{'height': itemSize}"
                            :title="$t('Load more options')" @click="() => autocompleteControl.loadMore()"
                            :class="{'focussed': autocompleteControl.data.length === autocompleteControl.navigation.currentIndex}">
                            {{$t('Show more')}}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </ODropdown>
</template>

<script lang="ts">
import type { ItemModel } from 'o365.modules.DataObject.Types.ts';
import type DataObject from 'o365.modules.DataObject.ts';

export type AutocompleteProps = {
    modelValue?: any,
    value?: any,
    dataObject?: DataObject,
    getData?: (pValue: string) => Promise<ItemModel>,
    itemTitle?: (pRow: ItemModel) => string,
    bind?: (pSel: ItemModel) => void,
    field: string,
    itemSize?: number,
    wrapperClass?: any,
    disableFirstItemAutoSelect?: boolean,
    disableCloseOnEnter?: boolean,
    id?: string,
    minWidth?: number | string,
    filterOperator?: string,
    hideNoResults?: boolean,
    /**
     * Only available with getData. When this is enabled
     * focusing the input will do a getData function call
     */
    searchOnFocus?: boolean
};
</script>

<script setup lang="ts">
import ODropdown from 'o365.vue.components.DropDown.vue';
import { ref, computed, reactive, onMounted, nextTick } from 'vue';
import AutocompleteControl from 'o365.controls.Autocomplete.ts';

const props = withDefaults(defineProps<AutocompleteProps>(), {
    itemSize: 24,
    minWidth: 200,
    filterOperator: 'beginswith'
});

const emit = defineEmits<{
    (e: 'update:modelValue', pValue: any): void,
    (e: 'enter:input', pEvent: KeyboardEvent, pValue: any): void
    (e: 'blur:noSelection', pEvent: FocusEvent, pInputValue: any, pFocusedValue: any): void
}>();

const dropdown = ref<any>(null);
const dropdownWidth = ref(200);
const containerRef = ref<HTMLDivElement|null>(null);
const isFocused = ref(false);

const autocompleteControl = reactive(new AutocompleteControl(props, {
    updateModelValue: (pValue: any) => emit('update:modelValue', pValue)
}));

const internalValue = computed({
    get() {
        return isFocused.value
            ? autocompleteControl.searchValue
            : props.modelValue ?? props.value;
    },
    set(value) {
        autocompleteControl.searchValue = value;
        emit('update:modelValue', value);
    }
});

function handleFocus() {
    autocompleteControl.searchValue = props.modelValue ?? props.value;
    autocompleteControl.onFocus();
    isFocused.value = true;
    // dropdown.value?.open();
}

function handleRawEnterPress(pEvent: KeyboardEvent) {
    if (autocompleteControl.navigation == null || autocompleteControl.navigation.currentIndex == null) {
        autocompleteControl.clearSearchDebounce();
        emit('enter:input', pEvent, internalValue.value);
        if (!props.disableCloseOnEnter) {
            window.requestAnimationFrame(() => {
                dropdown.value?.close();
                autocompleteControl.clearSearchDebounce();
            });
        }
    }
}

function onBeforeOpen() {
    dropdownWidth.value = dropdown.value.target.clientWidth;
    if (props.minWidth && dropdownWidth.value < props.minWidth) {
        dropdownWidth.value = +props.minWidth;
    }
}

function onOpen() {
    autocompleteControl.onOpen();
}

function onBeforeClose() {
    autocompleteControl.onClose();
}

async function onBlur(pEvent: FocusEvent) {
    isFocused.value = false;
    await nextTick()

    const skipClose = (pEvent.relatedTarget as any)?.closest('.o365-autocomplete-container') != null;
    if (!skipClose && !autocompleteControl.isSelected) {
        emit('blur:noSelection', pEvent, autocompleteControl.searchValue, autocompleteControl.getCurrent());
    }
    if (!skipClose && dropdown.value?.isOpen) {
        dropdown.value.close();
    }
}

onMounted(() => {
    autocompleteControl.initialize({
        dropdown: dropdown
    });
});

</script>

<style>

.o365-autocomplete-container {
    max-height: 250px;
}

.o365-autocomplete-item.focussed {
    background-color: rgba(var(--bs-primary-rgb), 1)!important;
    color: white!important;
}
.o365-autocomplete-item.focussed button {
    background-color: rgba(var(--bs-primary-rgb), 1)!important;
    color: white!important;
}

.loading-indicator {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
</style>